<template>
	<main class="page-container container">
		<header class="auction-header mb-20" :class="isMobileMedium ? 'flex-wrap' : ''">
			<app-text
				tag="h1"
				size="26"
				line-height="34"
				weight="600"
				:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"
				class="mr-30 flex-1"
			>{{ $t('auction') }}
			</app-text>
			<div class="d-flex flex-wrap gap-x-15">
				<app-input
					@keypress.enter="load()"
					v-model="query.Name"
					width="200"
					class="auction-header__input"
					:placeholder="this.$t('search')"
				>
					<template #prepend>
						<img src="@/assets/icons/search.svg" style="width: 16px" alt/>
					</template>
				</app-input>
				<app-button-a
					@click="isOpenFilterPanel = !isOpenFilterPanel"
					:width="0"
					class="auction-header__button"
					theme="gray-light"
					padding-x="24"
					font-weight="600"
				>
					<img src="@/assets/icons/sliders.svg" class="mr-10" alt/>
					<span>{{ $t('filter') }}</span>
				</app-button-a>
			</div>
		</header>
		<app-content-card
			v-if="isOpenFilterPanel"
			class="auction-filter mb-20"
			border
			:class="isMobile ? 'flex-wrap' : ''"
		>
			<block-wrap
				class="auction-filter__items-wrap"
				offset-x="15"
				:count="isMobileSmall ? '1' : isMobileMedium ? 2 : '3'"
			>
				<form-label
					v-model="query.lotNumber"
					class="auction-filter__item"
					component-type="input"
					:title="this.$t('lotNumber')"
				/>
				<form-label
					v-model="query.RegionId"
					class="auction-filter__item"
					:list="regions"
					item-value="id"
					item-name="name"
					:translatable="true"
					component-type="dropdown"
					:title="this.$t('region')"
					:placeholder="this.$t('chooseRegion')"
				/>
				<form-label
					v-model="query.CategoryId"
					class="auction-filter__item"
					:list="categories"
					item-value="id"
					item-name="name"
					:translatable="true"
					component-type="dropdown"
					:title="this.$t('category')"
					:placeholder="this.$t('chooseCategory')"
				/>

				<form-label
					v-model="query.PriceMin"
					class="auction-filter__item-large"
					:title="$t('priceFrom')"
				/>
				<form-label
					v-model="query.PriceMax"
					class="auction-filter__item-large"
					:title="$t('priceTo')"
				/>
			</block-wrap>
			<div class="auction-filter__buttons-wrap">
				<app-button-a
					@click="getCleanForms"
					:width="0"
					padding-x="10"
					class="auction-filter__reset-button color-text-light"
					theme="transparent"
					flex
				>
					<img src="@/assets/icons/trash.svg" class="mr-10" alt style="width: 18px"/>
					{{ $t('clearFilter') }}
				</app-button-a>
				<div class="auction-filter__buttons">
					<app-button-a
						@click="isOpenFilterPanel = false"
						class="mr-15"
						width="108"
						theme="gray-light"
					>{{ $t('cancel') }}
					</app-button-a>
					<app-button-a @click="load" width="108">{{ $t('show') }}</app-button-a>
				</div>
			</div>
		</app-content-card>
		<div class="mb-60">
			<auction-item
				v-for="item in data"
				:key="item.id"
				:lotNumber="item.lotNumber"
				:endDate="item.endDate"
				:name="item.name"
				:currentPrice="item.currentPrice"
				:lotSum="item.lotSum"
				:id="item.id"
				:regionName="item.regionName"
				:bidCount="item.bidCount"
				class="mb-20"
			/>
		</div>
		<div class="mb-60">
			<Pagination :total="this.total" :perPage="this.query.Take" @paginate="pagination($event)"></Pagination>
		</div>
	</main>
</template>
<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButtonA from "../../components/shared-components/AppButtonA";
import AppContentCard from "../../components/shared-components/AppContentCard";
import BlockWrap from "../../components/shared-components/BlockWrap";
import FormLabel from "../../components/shared-components/FormLabel";
import DoubleInput from "../../components/shared-components/DoubleInput";
import AuctionItem from "../../components/pages/auction/AuctionItem";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import ReferenceServer from "@/services/api/reference-service";

export default {
	components: {
		AppPagination,
		AuctionItem,
		DoubleInput,
		FormLabel,
		BlockWrap,
		AppContentCard,
		AppButtonA,
		AppInput,
		AppText,
		Pagination,
	},
	data() {
		return {
			isOpenFilterPanel: false,
			categories: [],
			regions: [],
			model: {
				price: {
					first: "",
					second: "",
				},
			},
			query: {
				LotNumber: null,
				Name: null,
				ProductId: null,
				RegionId: null,
				CategoryId: null,
				PriceMin: null,
				PriceMax: null,
				Skip: 0,
				Take: 10,
			},
			data: null,
			total: 0,
		};
	},
	created() {
		this.prepareCleanForms();

		this.getRegions();
		this.getCategories();
	},
	methods: {
		prepareCleanForms() {
			this.cleanQuery = JSON.parse(JSON.stringify(this.query));
			this.cleanModel = JSON.parse(JSON.stringify(this.model));
		},
		getCleanForms() {
			this.query = JSON.parse(JSON.stringify(this.cleanQuery));
			this.model = JSON.parse(JSON.stringify(this.cleanModel));
		},
		getRegions() {
			ReferenceServer.getRegions().then(
				(response) => {
					this.regions = response.data.result.data;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		getCategories() {
			ReferenceServer.getProductCategories().then(
				(response) => {
					this.categories = response.data.result.data;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		load() {
			this.query.PriceMin = this.model.price.first;
			this.query.PriceMax = this.model.price.second;
			this.$api
				.get(`/api-client/Auction/GetAllAuctionLot`, {params: this.query})
				.then(
					(response) => {
						const res = response.data;
						//console.log('auction ', res.result.total)
						if (res && res.result) {
							this.data = res.result.data;
							this.total = res.result.total;
						} else {
							this.$notification.error(res.error.message);
						}
					},
					(error) => {
						this.$notification.error(error);
					}
				)
				.finally(() => {
					this.loading = false;
				});
		},
		pagination(skip) {
			this.query.Skip = skip;
			this.load();
		},
	},
	mounted() {
		this.load();
	},
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/mixins";

.auction-filter {
	display: flex;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: calc(100% - 200px);
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		width: 200px;
	}

	&__buttons {
		display: flex;
		width: 231px;
		margin-left: -31px;
	}
}

.auction-header {
	display: flex;
	align-items: center;

	h1.text {
		flex-grow: 1;
	}

	&__input {
		margin-right: 15px;
		width: 250px;
	}

	&__button {
		svg {
			margin-left: -8px;
		}
	}
}

@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>
