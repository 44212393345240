<template>
	<div class="auction-item" :class="isMobile ? 'flex-wrap' : ''">
		<div class="auction-item__side-info" :class="isMobileSmall ? 'w-100' : ''">
			<div class="auction-item__lot-info mb-20">
				<app-text size="14" line-height="16" weight="600">№ лота: {{ this.lotNumber }}</app-text>
			</div>
			<app-text class="mb-20" size="16" line-height="18" weight="600">{{ name | translate }}</app-text>
			<div class="auction-item__link">
				<app-text
					class="link-title link-title--underline color-text-light mr-20"
					size="12"
					line-height="16"
					weight="500"
					>({{ this.bidCount }}) предложения</app-text
				>
				<app-text
					class="link-title link-title--underline color-text-light"
					size="12"
					line-height="16"
					weight="500"
					>({{ this.bidCount }}) товары/услуги</app-text
				>
			</div>
		</div>
		<div class="auction-item__content pa-20">
			<block-wrap offset-x="20" offset-y="15" class="mb-30"></block-wrap>

			<block-wrap offset-x="20" offset-y="15" :count="isMobile ? 1 : isDesktopSmall ? 2 : 4">
				<div class="auction-item__info-item">
					<app-text class="color-text-light mb-5" size="14" line-height="18">Дата окончания</app-text>
					<app-text size="14" line-height="18" weight="600">{{
						this.endDate | moment("DD-MM-YYYY HH:mm:ss")
					}}</app-text>
				</div>
				<div class="auction-item__info-item">
					<app-text class="color-text-light mb-5" size="14" line-height="18">Регион</app-text>
					<app-text size="14" line-height="18" weight="600">{{ this.regionName | translate }}</app-text>
				</div>
				<div class="auction-item__info-item">
					<app-text class="color-text-light mb-5" size="14" line-height="18">Стартовая цена</app-text>
					<app-text class="color-text-danger" size="14" line-height="18" weight="600">{{
						this.lotSum ? this.lotSum + " UZS" : "Null"
					}}</app-text>
				</div>
				<div class="auction-item__info-item">
					<app-text class="color-text-light mb-5" size="14" line-height="18">Текущая цена</app-text>
					<app-text class="color-text-success" size="14" line-height="18" weight="600">{{
						this.currentPrice ? this.currentPrice + " UZS " : " "
					}}</app-text>
				</div>
			</block-wrap>
		</div>
		<div class="auction-item__side-actions py-20 px-40" :class="isMobile ? 'w-100' : ''">
			<router-link :to="{ name: 'auction-single', params: { id: this.lotNumber } }">
				<app-button-a
					class="auction-item__button mb-15"
					:class="isMobile ? 'w-auto' : 'w-100'"
					radius="12"
					padding-x="0"
					>Подробнее</app-button-a
				>
			</router-link>
			<app-button-a class="auction-item__action color-text-light mb-15" text flex theme="transparent">
				<img src="../../../assets/icons/share-arrow.svg" class="mr-10" alt />
				<app-text class="color-text-light" size="14" line-height="17" weight="500">Поделиться</app-text>
			</app-button-a>
			<app-button-a
				class="auction-item__action"
				theme="transparent"
				:class="{ 'color-text-light': !isFavorite }"
				text
				flex
				@click="isFavorite = !isFavorite"
			>
				<img v-if="isFavorite" class="mr-10" src="../../../assets/icons/colored/star-filled-main.svg" alt />
				<img v-if="!isFavorite" class="mr-10" src="../../../assets/icons/star.svg" alt />
				<app-text class="color-text-light" size="14" line-height="17" weight="500"> Избранное </app-text>
			</app-button-a>
		</div>
	</div>
</template>
<script>
import AppText from "../../shared-components/AppText";
import BlockWrap from "../../shared-components/BlockWrap";
import AppButtonA from "../../shared-components/AppButtonA";

export default {
	name: "AuctionItem",
	components: { AppButtonA, BlockWrap, AppText },
	props: {
		lotNumber: String,
		endDate: String,
		name: Object,
		currentPrice: Number,
		lotSum: Number,
		id: Number,
		regionName: Object,
		bidCount: Number,
	},
	data() {
		return {
			isFavorite: false,
		};
	},
};
</script>
<style lang="scss" scoped>
.auction-item {
	width: 100%;
	display: flex;
	background: #ffffff;
	border-radius: 8px;

	&__side-info {
		// width: 37%;
		min-width: 290px;
		padding: 20px 20px 25px 30px;
		flex: 1;
	}

	&__side-actions {
		border-left: 1px solid #f3f3f3;
		// width: 12%;
		min-width: 200px;
	}

	&__content {
		// width: 55% !important;
		// flex-grow: 1;
	}

	&__info-item {
		// width: 22% !important;
		min-width: 150px;
		flex-shrink: 0;
	}

	&__title {
		width: calc(100% - 140px);
		flex-grow: 1;
	}

	&__link {
		width: 100px;
		align-items: center;
		white-space: nowrap;
		display: flex;
	}

	&__lot-info {
		width: 30%;
		min-width: 200px;
		padding: 8px 16px;
		text-align: center;
		white-space: nowrap;
		background: #dfeffd;
		border-radius: 4px;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
	}

	&__button {
		padding: 0 20px;
	}

	&__action {
		width: 100%;
		justify-content: flex-start;
	}

	&:last-child {
		margin-bottom: 0 !important;
	}
}
</style>

